<template>
	<div class="tabbed-view settings-page" v-if="app.user">
		<div class="tool-menu">
			<ul>
				<li v-for="tab in tabs">
					<app-link
						:to="tab.url"
						:class="tab.name === curTab ? 'current-item' : ''"
					>{{ ii(tab.text) }}</app-link>
				</li>
			</ul>
		</div>
		<div class="tool-tab">
			<div v-if="curTab === 'profile'" class="tool-box">
				<div>
					<h2>{{ ii('PROFILE') }}</h2>

					<form class="form">
						<div class="form-row">
							<div class="form-label">{{ ii('USERNAME') }}:</div>
							<div class="form-input">
								<s-text-field
									v-model="name"
									prepend-icon="user-circle"
									:error="profileErrors.name"
								></s-text-field>
							</div>
						</div>
						<div class="form-row">
							<div class="form-label">{{ ii('E_MAIL') }}:</div>
							<div class="form-input">
								<s-text-field
									v-model="email"
									prepend-icon="envelope-o"
									disabled="1"
								></s-text-field>
							</div>
						</div>
						<div class="form-btn-row">
							<s-btn icon="save" :loading="isProfileSaving" @click="saveUserInfo">{{ ii('SAVE') }}</s-btn>
						</div>
					</form>
				</div>
			</div>
			<div v-else-if="curTab === 'account'" class="tool-box">
				<h2>{{ ii('ACCOUNT') }}</h2>

				<form class="form">
					<div class="form-row">
						<div class="form-label">{{ ii('OLD_PASSWORD') }}:</div>
						<div class="form-input">
							<s-text-field
								v-model="oldPass"
								type="password"
								prepend-icon="lock"
								:error="(accountErrors.oldPass || accountErrors.general)"
							></s-text-field>
						</div>
					</div>
					<div class="form-row">
						<div class="form-label">{{ ii('NEW_PASSWORD') }}:</div>
						<div class="form-input">
							<s-text-field
								v-model="newPass"
								type="password"
								prepend-icon="lock"
								:error="accountErrors.newPass"
							></s-text-field>
						</div>
					</div>
					<div class="form-row">
						<div class="form-label">{{ ii('CONFIRM_PASSWORD') }}:</div>
						<div class="form-input">
							<s-text-field
								v-model="confirmPass"
								type="password"
								prepend-icon="lock"
								:error="accountErrors.confirmPass"
							></s-text-field>
						</div>
					</div>
					<div class="form-btn-row">
						<s-btn icon="save" :loading="isPasswordChanging" @click="savePassword">{{ ii('SAVE') }}</s-btn>
					</div>
				</form>
			</div>
			<div v-else-if="curTab === 'sessions'" class="tool-box">
				<h2>{{ ii('SESSIONS') }}</h2>
				<div class="general-tbl-outer">
					<table class="general-tbl">
						<thead>
						<tr>
							<th>{{ ii('ACTIVITY') }}</th>
							<th>IP</th>
							<th>{{ ii('USER_AGENT') }}</th>
							<th>{{ ii('SESSION') }}</th>

						</tr>
						</thead>
						<tbody>
							<tr v-for="sess in sessions">
								<td>
									<div class="nowrap">
									<span :title="sess.fullCreatedDate" class="help">
										<strong>{{ ii('FIRST_VISIT') }}:</strong>
										{{ sess.lastCreated }}
									</span>
									</div>
									<div class="nowrap">
									<span :title="sess.fullDate" class="help">
										<strong>{{ ii('LAST_VISIT') }}:</strong>
										{{ sess.lastAccessed }}
									</span>
									</div>
								</td>
								<td class="text-left">
									<s-flag class="geo" :cc="sess.lastGeo" ></s-flag>
									<app-link
										:to="'/ip-geolocation?ip=' + sess.lastIp"
										class="link"
									>{{ sess.lastIp }}</app-link>
								</td>
								<td class="text-center">
									<i :class="'fa fa-fw fa-' + sess.deviceType" :title="ii(sess.deviceType)"></i>
									<span :title="sess.osVersion" class="ua-itm help">{{ sess.os }}</span>
									<span :title="sess.browserVersion" class="ua-itm help">{{ sess.browser }}</span>
									<i class="ua-itm fa fa-info-circle help" :title="sess.userAgent"></i>
								</td>
								<td class="text-center">
									<strong v-if="sess.isCurrent" class="general-upper">{{ ii('CURRENT') }}</strong>
									<span
											v-else-if="sess.isRevoking"
											class="fa fa-spinner spin-fast"
									></span>
									<span
											v-else
											class="fa fa-remove link"
											:title="ii('REVOKE_SESSION')"
											@click="revokeSession(sess)"
									></span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<auth-form class="single-view" v-else></auth-form>
</template>

<script>
import * as moment from 'moment';

export default {
	data() {
		return {
			name: '',
			email: '',
			oldPass: '',
			newPass: '',
			confirmPass: '',
			sessions: null,

			curTab: null,
			tabs: [
				{url: '/settings', name: 'profile', text: 'Profile'},
				{url: '/settings/account', name: 'account', text: 'Account'},
				{url: '/settings/sessions', name: 'sessions', text: 'Sessions'}
			],
			profileErrors:{
				name:null
			},
			accountErrors:{
				general: null,
				oldPass: null,
				newPass: null,
				confirmPass: null
			},
			isPasswordChanging: false,
			isProfileSaving: false
		};
	},
	methods: {
		updateInfo() {
			if (this.app.user) {
				this.name = this.app.user.name;
				this.email = this.app.user.email;
			} else {
				this.name = '';
				this.email = '';
			}
		},
		async saveUserInfo() {
			this.profileErrors = {};
			if(!this.name){
				this.profileErrors.name = this.ii('PLEASE_FILL_USERNAME_FIELD');
				return;
			}
			this.isProfileSaving = true;
			await this.post('Auth.editUserName', this.name);
			this.isProfileSaving = false;
			this.app.refreshUser();
        },
		async getSessions() {
			if(!this.app.user)
				return;

			let res = await this.get('Auth.getSessions');

			res.forEach(sess => {
				let t = moment.unix(sess.visitedTs);
				let q = moment.unix(sess.createdTs);
				sess.fullDate = t.format(this.format || 'YYYY MMM D, HH:mm');
				sess.fullCreatedDate = q.format(this.format || 'YYYY MMM D, HH:mm');
				sess.lastAccessed = t.fromNow();
				sess.lastCreated = q.fromNow();
				sess.isRevoking = false;
			});
			this.sessions = res;
		},
		async revokeSession(session) {
			let ok = await this.confirm(this.ii('ARE_YOU_SURE_REVOKE_SESSION'));
			if (!ok) return;

			this.$set(session, 'isRevoking', true);
			await this.post('Auth.deleteSessionById', session.id);
			this.getSessions();
		},
		async savePassword() {
			this.accountErrors = {};
			if (!this.oldPass || !this.newPass || !this.confirmPass) {
				this.accountErrors.general = this.ii('PLEASE_FILL_ALL_THE_FIELDS');
				return;
			}

			this.isPasswordChanging = true;

			let res = await this.post('Auth.editPass', {
				oldPass: this.oldPass,
				newPass: this.newPass,
				confirmPass: this.confirmPass
			});

			this.isPasswordChanging = false;

			if (res.err) {
				this.accountErrors[res.err.field] = this.ii(res.err.msg);
			} else {
				this.oldPass = '';
				this.newPass = '';
				this.confirmPass = '';
				this.alert(this.ii('SUCCESS'));
			}
		},
		refreshCurTab() {
			let tab = this.tabs.find(tab => {
				let fullTabUrl = '/' + this.$route.params.lang + tab.url;
				return fullTabUrl === this.$route.path;
			});
			this.curTab = tab && tab.name;
		}
	},
	watch: {
		'app.user'() {
			this.updateInfo();
			this.getSessions();
		},
		$route() {
			this.refreshCurTab();
		}
	},
	async mounted() {
		this.refreshCurTab();
		await this.getSessions();
		this.updateInfo();
	}
}
</script>

<style lang="less">
@import "../styles/helpers";

.settings-page {
	form {
		width: 260px;
		max-width: 98%;
	}
	.ua-itm {
		padding-left: 5px;
	}
	.geo {
		padding-right: 5px;
	}
	.sessions-tbl {
		width: 600px;
		margin: 45px auto 60px;
		padding: 10px 10px 8px;
		border-collapse: collapse;
		& > tr > td {
			padding: 4px 4px;
		}
	}
}
</style>
