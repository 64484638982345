<template>
	<div class="single-view tool-sitemap-parser">
		<div class="tool-box" :class="isLoading ? 'tool-redirects-loading' : ''">
			<h1>{{ ii('SITEMAP_PARSER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
						type="text"
						v-model="app.query.url"
						prepend-icon="globe"
						ref="tf"
						:placeholder="ii('URL')"
						:loading="isLoading"
						:error="error"
						@change="onChange"
					></s-text-field>
				</div>

				<template v-if="sitemap">
					<div class="debug-items">
						<table class="result-tbl">
							<tbody>
							<tr>
								<td colspan="2" class="item-url-cell pointer">
									<div class="item-info">
										<span class="item-url">{{ sitemap.url }}</span>
										<span class="item-code">{{ sitemap.code }}</span>
									</div>
								</td>
							</tr>
							<tr v-if="sitemap.error">
								<td colspan="2" class="item-error">
									{{ sitemap.error }}
								</td>
							</tr>
							</tbody>
						</table>
					</div>

					<div class="subpanels-box">
						<app-link
							:to="{name: 'sitemapParser', query:{url: this.app.query.url}}"
							:class="app.routeName === 'sitemapParser' ? 'active' : ''"
						>{{ ii('INFO') }}</app-link><app-link
							:to="{name: 'sitemapParserUrls', query:{url: this.app.query.url}}"
							:class="app.routeName === 'sitemapParserUrls' ? 'active' : ''"
						>{{ ii('URLS') }}</app-link><app-link
							:to="{name: 'sitemapParserRaw', query:{url: this.app.query.url}}"
							:class="app.routeName === 'sitemapParserRaw' ? 'active' : ''"
						>{{ ii('RAW') }}</app-link>
					</div>

					<div class="result-data">
						<div v-if="app.routeName === 'sitemapParser'" class="json-data">
							<div v-html="html"></div>

							<span class="control-top">
								<s-copy-btn :text="sitemap.json || ''"></s-copy-btn>
							</span>
						</div>
						<div v-else-if="app.routeName === 'sitemapParserUrls'" class="raw-data">
							<div v-html="newLineToBr(wrapLinks(sitemap.locUrls || ''))"></div>

							<span class="control-top">
								<s-copy-btn :text="sitemap.locUrls || ''"></s-copy-btn>
							</span>
						</div>
						<div v-else-if="app.routeName === 'sitemapParserRaw'" class="raw-data">
							<pre class="text-left raw-pre">{{ sitemap.raw || '' }}</pre>

							<span class="control-top">
								<s-copy-btn :text="sitemap.raw || ''"></s-copy-btn>
							</span>
						</div>
					</div>
				</template>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				sitemap: null,
				error: null,
				isLoading: false
			};
		},
		computed: {
			html() {
				if (!this.sitemap || !this.sitemap.json) return '';
				if (!this.parseJson(this.sitemap.json)) return '';

				let json = this.beautifyJson(this.sitemap.json);
				let res = json.split('\n').map((line, index) => {
					let i = 0;
					line = line.replace(/ /g, '&nbsp;');
					line = line.replace(
						/""|"(.*?[^\\](\\\\)*?)"/g,
						(match, p1) => {
							++i;
							return `<span class="${i === 1 ? 'key' : 'value'}">"${this.escapeHtml(p1 || '')}"</span>`
						}
					);

					if (line.indexOf('"') === -1) {
						line = line.replace(/([0-9\.\-]+)/, '<span class="number">$1</span>');
						line = line.replace(/null/, '<span class="null">null</span>');
						line = line.replace(/(true)|(false)/, '<span class="bool">$1$2</span>');
					}

					line = line.replace(/<\/span>:&nbsp;([0-9\.\-]+)/, '</span>:&nbsp;<span class="number">$1</span>');
					line = line.replace(/<\/span>:&nbsp;null/, '</span>:&nbsp;<span class="null">null</span>');
					line = line.replace(/<\/span>:&nbsp;(true)|<\/span>:&nbsp;(false)/, '</span>:&nbsp;<span class="bool">$1$2</span>');
					if (!line.length) return line;

					let lastChar = line.substring(line.length - 1);
					let lastTwoChars = line.substring(line.length - 2);
					let lastThreeChars = line.substring(line.length - 3);
					if (lastChar === '{' || lastChar === '[') {
						line += '<span class="link fa fa-minus-square-o" id="show-hide-btn" data-index="line' + index + '"></span><span id="line' + index + '">';
					} else if ((lastChar === '}' || lastChar === ']') && lastTwoChars !== '{}' && lastTwoChars !== '[]') {
						line = line.substring(0, line.length - 1) + '</span>' + lastChar
					} else if ((lastTwoChars === '},' || lastTwoChars === '],') && lastThreeChars !== '{},' && lastThreeChars !== '[],') {
						line = line.substring(0, line.length - 2) + '</span>' + lastTwoChars
					}

					return line;
				});

				let html = res.join('<br>');
				html = html.replace(/(https?:\/\/[^\s"'(),]+)[^\.\s,]/gi, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>')

				return html;
			}
		},
		methods: {
			async getChain() {
				this.error = null;

				if (this.isLoading) return;

				let url = this.app.query.url;
				if (!url || !url.trim()) {
					this.items = null;
					return;
				}

				this.hit('redirect_url_enter', url);

				this.isLoading = true;

				try {
					let res = await this.get('Sitemap.parseByUrl', url);
					this.sitemap = res.sitemap;
				} catch (err) {
					this.error = err.message;
					this.items = null;
				}

				this.isLoading = false;
				this.$refs.tf.focus();
			},
			collapseJsonAction(e) {
				if (!e.target || e.target.id !== 'show-hide-btn') return;

				let btn = e.target;
				let id = btn.dataset.index;
				let element = document.getElementById(id);

				if (element.style.display) {
					btn.className = 'link fa fa-minus-square-o';
					element.style.display = '';
				} else {
					btn.className = 'link fa fa-plus-square-o';
					element.style.display = 'none';
				}
			},
			onChange() {
				this.refreshQuery();
				this.getChain();
			}
		},
		mounted() {
			window.addEventListener('click', this.collapseJsonAction);

			this.$refs.tf.focus();
			this.getChain();
		},
		destroyed() {
			window.removeEventListener('click', this.collapseJsonAction);
		}
	}
</script>

<style lang="less">
	@import "../styles/helpers";

	.tool-sitemap-parser {
		.debug-items {
			.transition(all 0.1s);

			td.item-url-cell {
				background: #eee !important;
			}

			.item-info {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.item-url {
					padding: 5px 10px;
					word-break: break-word;
					font-size: 15px;

					a {
						font-size: 13px;
					}
				}

				.item-code {
					border: 1px solid #ddd;
					padding: 1px 5px 3px 5px;
					font-weight: 500;
					font-size: 16px;
					min-width: 50px;
					text-align: center;
					background: #fff;
				}
			}

			.item-error {
				display: flex;
				padding: 2px 5px;
				font-weight: 500;
				font-size: 14px;
				color: #f00;
			}
		}

		&.tool-redirects-loading .redirect-items {
			opacity: 0.3;
		}

		.result-tbl {
			> tbody > tr {
				max-width: 450px;
				> td:nth-child(1) {
					text-transform: none !important;
				}
			}
		}

		.result-data {
			.monospace();
			position: relative;
			border: 1px solid #aaa;
			min-height: 150px;
			border-radius: 3px;
			padding: 2px 8px;
			overflow: auto;
			margin-top: 20px;

			.control-top {
				position: absolute;
				right: 7px;
				top: 5px;

				& > span {
					margin-left: 5px;
				}
			}

			.raw-data {
				font-size: 13px;
				margin: 0;


				.raw-pre {
					white-space: pre-wrap;
				}
			}

			.json-data {
				font-size: 13px;
				margin: 0;

				.key {
					color: #4c78ab;
				}

				.value {
					color: green;
				}

				.number {
					color: coral;
				}

				.null {
					color: red;
				}

				.bool {
					color: blueviolet;
				}

				#show-hide-btn {
					position: relative;
					bottom: -2.5px;
					font-size: 14px;
					margin: 0 3px;
				}
			}
		}
	}
</style>
