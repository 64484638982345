<template>
	<div class="single-view">
		<div class="tool-box">
			<h1>{{ ii('PASSWORD_GENERATOR') }}</h1>
			<div>
				<div>
					<table>
						<tr>
							<td>{{ ii('ENTER_A_NUMBER') }}:</td>
							<input
								type="number"
								v-model="app.query.passwordLength"
								ref="tf"
								placeholder="Password length: "
								@change="refreshQuery"
							>
						</tr>
						<tr>
							<td>{{ ii('A_Z_LOWERCASE') }}:</td>
							<td><input
								type="checkbox"
								v-model="app.query.lowercase"
								ref="tf"
								@change="refreshQuery"
							></td>
						</tr>
						<tr>
							<td>{{ ii('A_Z_UPPERCASE') }}:</td>
							<td><input
								type="checkbox"
								v-model="app.query.uppercase"
								ref="tf"
								@change="refreshQuery"
							></td>
						</tr>
						<tr>
							<td>{{ ii('0_9_NUMBERS') }}:</td>
							<td><input
								type="checkbox"
								v-model="app.query.numbers"
								ref="tf"
								@change="refreshQuery"
							></td>
						</tr>
						<tr>
							<td>{{ ii('specific_symbols') }}:</td>
							<td><input
								type="checkbox"
								v-model="app.query.symbols"
								ref="tf"
								@change="refreshQuery"
							></td>
						</tr>
					</table>
					<table class="result-tbl">
						<tr>
							<td>{{ ii('Password') }}:</td>
							<td>
								<span>{{ password }}</span>
							</td>
							<td>
								<s-copy-btn :text="password"></s-copy-btn>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    function getRandomInteger(min, max) {
        let rand = min - 0.5 + Math.random() * (max - min + 1);
        return Math.round(rand);
    }

    function generatePassword(arr, length) {
        let password = '';

        for (let i = 0; i < length; i++) {
            password += arr[getRandomInteger(0, arr.length - 1)];
        }

        return password
    }

    export default {
        computed: {
            password() {
                let arr_num = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
                let arr_en = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
                let arr_EN = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
                let arr_symb = ['!', '@', '#', '$', '%', '?', '-', '+', '=', '~'];

                let arr = [];
                let password = '';

                if (this.app.query.lowercase) arr = arr.concat(arr_en);
                if (this.app.query.uppercase) arr = arr.concat(arr_EN);
                if (this.app.query.numbers) arr = arr.concat(arr_num);
                if (this.app.query.symbols) arr = arr.concat(arr_symb);

                if (arr.length === 0) return "any checkbox selected";
                if (this.app.query.passwordLength >= 100) return "Can`t generate too long password. Enter length < 100";

                password = generatePassword(arr, this.app.query.passwordLength);

                return password;
            }
        },
        name: "Password.vue",
        mounted() {
            if (!this.app.isMob) {
                this.$refs.tf.focus();
            }
            this.update();
        }
    }
</script>

<style lang="less">
</style>